



















































import VendorService from '@/services/vendors.service.ts';
import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useVendorsStore } from '@/store/vendors-store';
import cloneDeep from 'lodash/cloneDeep';
import { VendorFormData } from '@/model/vendor-form-data';

const DEFAULT_SLACK_RESPOONSE = {
  incoming_webhook: {},
  team: {
    name: ''
  }
};

export default Vue.extend({
  name: 'VendorForm',
  components: { ConfirmModal },
  data() {
    return {
      revokingSlackModal: {} as IConfirmModal,
      slackToggle: false,
      slackState: '',
      slackResponse: cloneDeep(DEFAULT_SLACK_RESPOONSE)
    };
  },
  computed: {
    ...mapStores(useVendorsStore),
    sendingData(): boolean {
      return this.vendorsStore.saving;
    },
    vendor(): VendorFormData {
      return this.vendorsStore.form;
    },
    errors(): {} {
      return this.vendorsStore.validationErrors;
    },
    source(): string {
      return `https://slack.com/oauth/v2/authorize?client_id=17699540785.2594598455719&scope=incoming-webhook&user_scope=&state=${this.slackState}&redirect_uri=${process.env.VUE_APP_API}/vendors/slack`;
    }
  },
  created() {
    if (this.vendor.slack_response && this.vendor.slack_state === null) {
      this.slackToggle = true;
      this.slackResponse = JSON.parse(this.vendor.slack_response);
    }
    this.revokingSlackModal = (this.$refs
      .revokingSlackModal as unknown) as IConfirmModal;
  },
  methods: {
    async onSlackToggle() {
      if (!this.slackToggle) {
        this.slackState = await VendorService.getSlackState(this.vendor);
        this.slackToggle = !this.slackToggle;
      } else if (this.slackToggle && this.slackResponse.team.name === '') {
        this.slackToggle = !this.slackToggle;
      } else {
        this.openRevokingSlackModal();
      }
    },
    openRevokingSlackModal() {
      this.revokingSlackModal.openModal(
        this.$t('confirmModal.revokingSlack.message', {
          team: this.slackResponse.team.name
        }),
        {
          okTitle: this.$t('button.delete'),
          okVariant: 'danger'
        },
        null
      );
    },
    async onRevokingSlack() {
      try {
        await this.vendorsStore.revokingSlack(this.vendor);
        this.slackToggle = false;
        this.slackResponse = cloneDeep(DEFAULT_SLACK_RESPOONSE);
      } catch (e) {
      } finally {
        this.revokingSlackModal.closeModal();
      }
    }
  }
});
