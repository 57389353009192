var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-3 bg-light border-radius"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('vendorSignUp.generalSettings.slack'))+" ")]),_c('div',[_c('label',{staticClass:"switch"},[_c('input',{class:{
            checked: _vm.slackToggle
          },attrs:{"type":"checkbox"},domProps:{"value":_vm.slackToggle},on:{"click":_vm.onSlackToggle}}),_c('span',{staticClass:"slider round"})]),(!_vm.slackToggle)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('settings.integrations.slackMessage')))]):(_vm.slackToggle && _vm.slackResponse.team.name.length === 0)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('settings.integrations.addApp'))+" "),_c('a',{attrs:{"href":_vm.source}},[_c('img',{attrs:{"alt":"Add to Slack","height":"40","width":"139","src":"https://platform.slack-edge.com/img/add_to_slack.png","srcSet":"https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"}})])]):_c('span',{staticClass:"ml-2",domProps:{"innerHTML":_vm._s(
          _vm.$t('settings.integrations.appAdded', {
            team: _vm.slackResponse.team.name,
            channel: _vm.slackResponse.incoming_webhook.channel
          })
        )}})])]),_c('confirm-modal',{ref:"revokingSlackModal",on:{"confirm":_vm.onRevokingSlack}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }